import React, { useEffect, useState, useRef } from "react";
import { AppBar } from "@mui/material";
import styled from "@mui/styled-engine";
import Container from "@mui/material/Container";
import Tabletop from "tabletop";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";

import { flexbox } from "@mui/system";

import Papa from "papaparse";

import PersonCard from "./PersonCard.js";
var _ = require("lodash");
const ECFSBar = styled(AppBar)({
  backgroundColor: "white",
  textColor: "black",
});

export default function FinalApp() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const SearchRef = useRef();
  const [dept, setDept] = React.useState("ECFS");
  const [div, setDiv] = React.useState("ECFS");
  const [name, setName] = React.useState("");
  const [data, setData] = useState({
    divisions: ["ECFS", "Ethical Culture", "Fieldston Lower", "Fieldston Middle", "Fieldston Upper"],
    departments: ["ECFS"],
    loading: true,
    filteredData: [],
  });
  useEffect(() => {
    Papa.parse(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vT_hGXjk6gNimxpmzeldHuC-CaZeMREJHFqbi4n0VvxZuSyRqDr2FQAorhAeoaTrjbLRBmc0ebHuSEX/pub?gid=0&single=true&output=csv",
      {
        download: true,
        header: true,
        complete: (results) => {
          setData({
            ...data,
            departments: ["ECFS", ..._.orderBy(_.uniq(results.data.map((user) => user.department.split(",")[0])), (dept) => dept.toLowerCase())],
            loading: false,
            raw: results.data,
            filteredData: _.orderBy(results.data, ["name"]),
          });
        },
      }
    );
    Tabletop.init({
      key: "187cjvjLlbjlE8VqvBXvybdB2idg27cQn0tAx0RZSXFs",
      simpleSheet: true,
    })
      .then((responseData) => {
        // console.log(responseData);
      })
      .catch((err) => console.warn(err));
  }, []);

  const handleChangeDept = (event) => {
    setDept(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeDiv = (event) => {
    setDiv(event.target.value);
  };

  const filterResults = () => {
    let filteredResults = data.raw;
    if (dept != "ECFS") {
      filteredResults = _.filter(filteredResults, (user) => user.department.indexOf(dept) > -1);
    }
    if (div != "ECFS") {
      filteredResults = _.filter(filteredResults, (user) => user.division.indexOf(div) > -1);
    }

    filteredResults = _.filter(
      filteredResults,
      (user) =>
        user.email.toLowerCase().indexOf(name.toLowerCase()) > -1 ||
        user.phone.toLowerCase().indexOf(name.toLowerCase()) > -1 ||
        user.title.toLowerCase().indexOf(name.toLowerCase()) > -1 ||
        user.department.toLowerCase().indexOf(name.toLowerCase()) > -1 ||
        user.name.toLowerCase().indexOf(name.toLowerCase()) > -1
    );
    setData({
      ...data,
      filteredData: filteredResults.sort(function (a, b) {
        var textA = a.name.toLowerCase().toUpperCase();
        var textB = b.name.toLowerCase().toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }),
    });
  };

  return (
    <React.Fragment>
      <ECFSBar>
        <Box sx={{ paddingLeft: 5, display: "flex", alignItems: "center" }}>
          <a href="/">
            <img src={process.env.PUBLIC_URL + "/ecfs.png"} style={{ width: 40, padding: 5 }} />
          </a>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,

              fontFamily: "Sanomat-Regular",
              fontWeight: 700,
              color: "black",
              textDecoration: "none",
            }}
          >
            ECFS
          </Typography>
        </Box>
      </ECFSBar>
      {data.loading ? (
        <CircularProgress style={{ position: "absolute", left: "50%", top: "50%" }} />
      ) : (
        <Container maxWidth="lg" style={{ marginTop: 100 }}>
          <Typography
            variant="h2"
            component="a"
            gutterBottom
            href="/"
            sx={{
              mr: 2,
              fontFamily: "Sanomat-Regular",
              fontWeight: 400,

              color: "black",
              textDecoration: "none",
            }}
          >
            Faculty & Staff Directory
          </Typography>

          <Box>
            <form
              style={{ display: "flex", flexWrap: "wrap", gap: 10 }}
              onSubmit={(e) => {
                e.preventDefault();
                filterResults();
              }}
            >
              <label style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                Keyword <br />
                <input
                  id="keywordField"
                  type="text"
                  value={name}
                  onChange={handleChangeName}
                  placeholder="Keyword"
                  style={{ minWidth: 300, padding: 10, marginTop: 10, flexGrow: 1 }}
                ></input>
              </label>

              <label style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                Department <br />
                <select value={dept} onChange={handleChangeDept} name="cars" id="cars" form="carform" style={{ marginTop: 10, padding: 10, minWidth: 300 }}>
                  {data.departments.map((elem) => (
                    <option value={elem}>{elem}</option>
                  ))}
                </select>
              </label>
              <label style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                Division <br />
                <select value={div} onChange={handleChangeDiv} name="cars" id="cars" form="carform" style={{ marginTop: 10, padding: 10, minWidth: 200 }}>
                  {data.divisions.map((elem) => (
                    <option value={elem}>{elem}</option>
                  ))}
                </select>
              </label>
              <button
                type="submit"
                style={{
                  alignSelf: "flex-end",
                  flexGrow: 2,
                  letterSpacing: "0.1em",
                  padding: 10,
                  textTransform: "uppercase",
                  textAlign: "center",
                  backgroundColor: "#F55002",
                  color: "white",
                  fontSize: 16,
                  border: "none",
                }}
              >
                Search Directory
              </button>
            </form>
          </Box>
          <hr style={{ marginTop: 10 }} />
        </Container>
      )}

      <Container maxWidth="lg" style={{ marginTop: 10, display: "flex", gap: 10, flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
        {data.filteredData.map((user) => (
          <PersonCard user={user} />
        ))}
      </Container>
    </React.Fragment>
  );
}
