import React, { useEffect, useState, useRef } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
export default function PersonCard(props) {
  return (
    <Card variant="outline" style={{ width: 350 }}>
      <CardContent>
        <div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div style={{ fontFamily: "Sanomat-Regular", fontSize: 24 }}>{props.user.name}</div>
            <Avatar
              src={"data:image/jpe;base64, " + props.user.picture.replace(/_/g, "/").replace(/-/g, "+")}
              style={{
                marginTop: "-10px",
                marginLeft: 5,
                width: "48px",
                height: "48px",
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>{props.user.title}</div>
          <div>{props.user.department}</div> <div>{props.user.division}</div>
          <div>{props.user.email}</div>
          <div>{props.user.phone || " _"}</div>
        </div>
      </CardContent>
    </Card>
  );
}
